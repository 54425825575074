<template>
  <div id="app" v-wechat-title="$route.meta.title">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>-->
    <!-- <router-view  /> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
// import DevicePixelRatio from './utils/devicePixelRatio';
import drawMixin from "./utils/drawMixin";
export default {
  mixins: [ drawMixin ],
  created() {
    // new DevicePixelRatio().init();
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.el-dialog__wrapper .el-icon-close:before {
    color: #66892f !important;
    font-size: 30px !important;
}
/* @media screen and (max-width: 1980px) */

/* @media screen and (height: 1080px); */
.pagination-content .el-scrollbar li.selected,.pagination-content .el-scrollbar li.hover{
    color: #66892F !important;
  }
</style>
